import Paragraph from '@components/paragraph';
import ViewContainer from '@components/viewContainer';
import React from 'react';

const Kenshikai = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h1 className="title is-4">Okinawa Goju Ryu Karate</h1>
            <Paragraph>
                Karatella on kamppailulajina pitkä historia, jonka juuret ulottuvat Kiinaan ja
                Okinawan saarille. Karate kehittyi nykymuotoonsa Okinawalla 1800 luvun
                loppupuolella, josta se Okinawalaisten mestareiden toimesta vietiin Japanin
                pääsaarille 1900 luvun alkupuolella.
            </Paragraph>
            <Paragraph>
                Goju ryu karaten isänä pidetään Kanryo Higaonnaa (1853-1915). Hän sai merkittävimmät
                vaikutteensa kamppailutyyliinsä Kiinasta Fuzhousta, jossa hän opiskeli vuosikausia
                kiinalaisten mestarien opastuksella, näistä tärkeimpänä Ryu Ryu Ko.
            </Paragraph>
            <Paragraph>
                Palattuaan 14 vuotta kestäneiltä Kiinan matkoiltaan Okinawalle hän alkoi opettaa
                Nahan Nishiharassa kamppailutaitoja. Hänen opettamansa tyyli sai nimekseen Naha-te.
                Yksi hänen tärkeimmistä oppilaistaan oli Chojun Miyagi (1888-1953). Miyagia pidetään
                goju ryun perustajana. Goju Ryu kehittyi yhdeksi karaten suurimmista ja
                tunnetuimista tyylisuunnista maailmanlaajuisesti ja on sitä myös tänä päivänä.
            </Paragraph>
            <Paragraph>
                Nimi goju ryu tarkoittaa kova-pehmeä –koulukuntaa. Go eli kova tarkoittaa myös
                suoraa tekniikkaa, uloshengitystä sekä ulkoista voimaa. Ju eli pehmeä sisältää
                käsitteenä myös pyöreät tekniikat, sisäänhengityksen ja sisäisen voiman. Näihin
                perusperiaatteisiin nojaa goju ryu karaten harjoittelu.
            </Paragraph>
            <Paragraph>
                Okinawan goju ryu harjoittelussa korostuu muihin karaten tyylisuuntiin verrattuna
                enemmän lähikamppailu. Tärkeä osa harjoittelua on pyrkiä kehittämään voimaa, jolla
                pystytään suorittamaan tehokkaita tekniikoita lähietäisyydeltä. Voiman tuotto
                pohjautuu fyysiseen tekniikkaan, henkiseen asenteeseen sekä nämä yhteen sitovaan
                hengitykseen.
            </Paragraph>
            <Paragraph>
                Karaten harjoittelu muodostuu kolmesta pääosa-alueesta, jotka ovat kata
                (liikesarjat), kihon (perustekniikkaharjoittelu) ja kumite (pariharjoittelu eri
                muodoissaan).
            </Paragraph>
        </ViewContainer>
    );
};

export default Kenshikai;
